import React, {useState, useEffect, useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {EditorState, ContentState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {useTranslate} from '@computerrock/formation-i18n';
import {matchPath} from '@computerrock/formation-router';
import {FileAssetsDropZone, FileAssetsInput, useFileAssets} from '@ace-de/eua-file-assets';
import {alfMessageChannelTypes, alfMessageTypes, alfMessageTextTemplateTypes, alfOutgoingMessageCategoryTypes, etmTaskTypes, etmLeistTaskCategoryTypes, alfInvoiceChannelTypes, languages, persistenceStates} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {Modal, useStyles, ContentBlock, ContentItem, HighlightCircle, MultiSelectField, MultiSelectOption} from '@ace-de/ui-components';
import {SelectField, Option, InputField, DateField, TimeField, Checkbox, Form, ButtonPrimary, RichTextAreaField} from '@ace-de/ui-components/form';
import {Icon, calendarIcon, emailIcon, closeIcon, deleteIcon, infoAlertIcon, fileIcon, uploadIcon, plusIcon} from '@ace-de/ui-components/icons';
import * as communicationActionTypes from '../communicationActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import * as contactSelectors from '../../contacts/contactSelectors';
import categoryToTextTemplatesMap from '../categoryToTextTemplatesMap';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import allowedAttachmentMIMETypes from '../../invoices/allowedAttachmentMIMETypes';
import styles from './CommunicationModals.module.scss';
import config from '../../config';
import routePaths from '../../routePaths';

const mandatoryFieldSets = {
    [alfMessageChannelTypes.EMAIL]: ['toEmail', 'sender'],
    [alfMessageChannelTypes.MAIL]: ['street', 'postCode', 'city'],
    [alfMessageChannelTypes.ELLA]: [],
};

const languageOptions = {
    [languages.GERMAN]: 'de-DE',
    [languages.ENGLISH]: 'en-US',
};

const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const SendMessageModal = props => {
    const {cx} = useStyles(props, styles);
    const {hasBackdrop, serviceCase, serviceCaseInvoices, accountPartyContactDetails, invoice, senderAccounts} = props;
    const {declineSendMessage, confirmSendMessage, fetchAccountPartyDetails, serviceCaseInvoice} = props;
    const {createTask, activeUser, selectedSearchContact, persistenceState, serviceCaseDocuments, location} = props;
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateModal = createTranslateShorthand('send_message_modal');

    const [richTextAreaContent, setRichTextAreaContent] = useState(EditorState.createEmpty());
    const [selectedRecipient, setSelectedRecipient] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[languages.GERMAN]);
    const [mailFormData, setMailFormData] = useState();
    const [emailFormData, setEmailFormData] = useState();
    const [subject, setSubject] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [initialContactInformation, setInitialContactInformation] = useState();
    const [isSendingDisabled, setIsSendingDisabled] = useState(true);
    const [shouldCreateTask, setShouldCreateTask] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [name, setName] = useState();
    const [emailError, setEmailError] = useState('');
    const [shouldSetContactInformation, setShouldSetContactInformation] = useState(false);
    const [isDueTimeValid, setIsDueTimeValid] = useState();
    const [selectedAttachments, setSelectedAttachments] = useState();
    const contactsScreenMatch = matchPath(location?.pathname, {path: routePaths.CONTACTS, exact: true});
    const serviceCaseScreenMatch = matchPath(location?.pathname, {path: routePaths.SERVICE_CASE, exact: true});

    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'alfFileAssetsService',
        fileList: 'service-case-incoming-messages',
    });

    const isSendingInProgress = persistenceState === persistenceStates.PENDING;

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const validateMandatoryFields = useCallback(() => {
        if (selectedRecipient === 'OTHER' && !name) {
            if (!isSendingDisabled) {
                setIsSendingDisabled(true);
            }
            return;
        }

        if (shouldCreateTask && (!dueDate || !dueTime || !isDueTimeValid)) {
            if (!isSendingDisabled) {
                setIsSendingDisabled(true);
            }
            return;
        }

        if (!selectedChannel || !selectedRecipient || !richTextAreaContent.getCurrentContent().hasText()) {
            if (!isSendingDisabled) {
                setIsSendingDisabled(true);
            }
            return;
        }

        if (selectedChannel && selectedChannel === alfMessageChannelTypes.EMAIL) {
            if (!emailFormData || mandatoryFieldSets[alfMessageChannelTypes.EMAIL].some(field => {
                return !emailFormData[field];
            }) || !!emailError) {
                if (!isSendingDisabled) {
                    setIsSendingDisabled(true);
                }
                return;
            }
        }

        if (selectedChannel && selectedChannel === alfMessageChannelTypes.MAIL) {
            if (!mailFormData || mandatoryFieldSets[alfMessageChannelTypes.MAIL].some(field => {
                return !mailFormData[field];
            })) {
                if (!isSendingDisabled) {
                    setIsSendingDisabled(true);
                }
                return;
            }
        }

        setIsSendingDisabled(false);
    }, [
        setIsSendingDisabled,
        selectedChannel,
        selectedRecipient,
        richTextAreaContent,
        isSendingDisabled,
        mailFormData,
        emailFormData,
        dueDate,
        dueTime,
        shouldCreateTask,
        name,
        emailError,
        isDueTimeValid,
    ]);

    const getTextWidth = text => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = getComputedStyle(document.body).font;

        return context.measureText(text).width;
    };

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price > 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '-';
    };

    const createArgumentsArray = (category, template, language) => {
        const {lines, totalInvoicedAmount} = invoice || serviceCaseInvoice;
        let alignedText = [];
        let alignedTotalAmountText = '';

        const serviceApprovedAmountsText = lines.map(line => (
            line.approvedAmount
                ? `${translate.apply(this, [
                    `global.service_type.${line.service.toLowerCase()}`,
                    {},
                    language,
                ])}: ${getPrice({
                    price: line.approvedAmount,
                    currency: config.CURRENCY,
                })}` : null
        )).filter(approvedAmount => !!approvedAmount);

        const hasApprovedAmounts = !!serviceApprovedAmountsText.length;

        if ([alfOutgoingMessageCategoryTypes.REFUND, alfOutgoingMessageCategoryTypes.REPAYMENT].includes(category)
            && lines && hasApprovedAmounts) {
            serviceApprovedAmountsText.push(`${translateModal('text.total_amount')}: ${getPrice({
                price: totalInvoicedAmount,
                currency: config.CURRENCY,
            })}`);

            const MAX_STRING_LENGTH = Math.max(...serviceApprovedAmountsText.map(line => getTextWidth(line)));
            const SPACE_WIDTH = getTextWidth(' ');

            alignedText = [...serviceApprovedAmountsText].map(string => {
                const widthDiff = MAX_STRING_LENGTH - getTextWidth(string);
                const spaceCount = Math.round(widthDiff / SPACE_WIDTH);
                if (!string) return null;
                const [textPart, amount] = string.split(':');
                return (serviceApprovedAmountsText.indexOf(string) === serviceApprovedAmountsText.length - 1)
                    ? ' '.repeat(spaceCount + config.DEFAULT_SPACE_COUNT - 1) + amount.trim()
                    : `${textPart.trim()}:` + ' '.repeat(spaceCount + config.DEFAULT_SPACE_COUNT) + amount.trim();
            });

            alignedTotalAmountText = alignedText.pop();
        }

        switch (category) {
            case alfOutgoingMessageCategoryTypes.OTHER: {
                switch (template) {
                    case alfMessageTextTemplateTypes.REGRESS_INSURANCE: {
                        return {
                            memberName: `${serviceCase?.member?.personalDetails?.displayName || '-'}`,
                            serviceCaseId: `${serviceCase?.prefix}-${serviceCase?.id}`,
                            totalAmount: `${getPrice({price: totalInvoicedAmount, currency: config.CURRENCY})}` || '-',
                        };
                    }
                    case alfMessageTextTemplateTypes.REQUEST_POLICE_OR_PUBLIC_PROSECUTOR_BLOOD_ALCOHOL: {
                        return {
                            memberName: `${serviceCase?.member?.personalDetails?.displayName || '-'}`,
                        };
                    }
                    default: {
                        return {};
                    }
                }
            }
            case alfOutgoingMessageCategoryTypes.REPAYMENT:
            case alfOutgoingMessageCategoryTypes.REFUND: {
                return {
                    serviceApprovedAmounts: `${hasApprovedAmounts ? `${category === alfOutgoingMessageCategoryTypes.REPAYMENT
                        ? '' : '\n\n'}${alignedText.map(data => data).join('\n')}\n\n` : ''}`,
                    totalAmount: `${alignedTotalAmountText || '-'}`,
                };
            }
            default: {
                return {};
            }
        }
    };

    const formatMessageText = (category, template, language = selectedLanguage) => {
        if (!category || !template) return;

        if (!categoryToTextTemplatesMap[category].includes(template)) {
            setRichTextAreaContent(EditorState.createEmpty());
            return;
        }

        const argsArray = createArgumentsArray(category, template, language);
        let firstParagraph;
        let thirdParagraph;

        if (![alfOutgoingMessageCategoryTypes.ELLA, alfOutgoingMessageCategoryTypes.OTHER].includes(category)) {
            firstParagraph = translateModal.apply(this, [
                `first_paragraph.${category.toLowerCase()}`,
                {...argsArray},
                language,
            ]);
            thirdParagraph = translateModal.apply(this, [
                `third_paragraph.${category.toLowerCase()}`,
                {...argsArray},
                language,
            ]);
        }

        const secondParagraph = template !== alfMessageTextTemplateTypes.GENERAL_REFUND
            ? translateModal.apply(this, [
                `second_paragraph.${category.toLowerCase()}_${template.toLowerCase()}`,
                {...argsArray}, language,
            ])
            : '';

        // ToDo: remove replaceAll logic if '\n' symbol can be added to translation json files.
        const formattedText = `${firstParagraph ? `${firstParagraph}\n\n` : ''}${secondParagraph.replaceAll('&', '\n\n') || ''}${thirdParagraph ? `${secondParagraph && '\n\n'}${thirdParagraph.replaceAll('&', '\n\n')}` : ''}`;
        setRichTextAreaContent(EditorState.createWithContent(ContentState.createFromText(formattedText)));
    };

    const handleMailFormChange = formValues => {
        setMailFormData(formValues);
    };

    const handleEmailFormChange = formValues => {
        if (formValues.toEmail) {
            setEmailError(translate(validateEmail(formValues.toEmail) ? '' : 'global.email_error.wrong_format'));
        }
        setEmailFormData(formValues);
    };

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape' && persistenceState !== persistenceStates.PENDING) declineSendMessage();
    }, [declineSendMessage, persistenceState]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        validateMandatoryFields();
    }, [
        mailFormData,
        emailFormData,
        richTextAreaContent,
        selectedRecipient,
        selectedChannel,
        validateMandatoryFields,
        shouldCreateTask,
        dueDate,
        dueTime,
        name,
    ]);

    useEffect(() => {
        if (selectedRecipient && !mailFormData && initialContactInformation) {
            setMailFormData(initialContactInformation);
        }
    }, [selectedRecipient, mailFormData, initialContactInformation]);

    useEffect(() => {
        if (!accountPartyContactDetails) {
            setInitialContactInformation(null);
        }
        if (shouldSetContactInformation && accountPartyContactDetails) {
            setInitialContactInformation({
                street: accountPartyContactDetails.address?.street || '',
                postCode: accountPartyContactDetails.address?.postCode || '',
                city: accountPartyContactDetails.address?.city || '',
                country: accountPartyContactDetails.address?.country || '',
                toEmail: accountPartyContactDetails.email || '',
            });
            // todo maybe set values to these automatically?
            setShouldSetContactInformation(false);
        }
    }, [shouldSetContactInformation, accountPartyContactDetails, setInitialContactInformation]);

    const handleOnMessageSend = () => {
        const htmlMessageContent = stateToHTML(richTextAreaContent.getCurrentContent())
            .replace(/<p>(.*?)<\/p>/g, (match, innerContent) => {
                const trimmedInnerContent = innerContent.trim();
                if (trimmedInnerContent === '<br>') return '<br>';
                return `${trimmedInnerContent}<br>`;
            });

        const contactPartyAddress = [
            initialContactInformation?.street,
            initialContactInformation?.postCode,
            initialContactInformation?.city,
            initialContactInformation?.country,
        ];
        const contactAddress = selectedChannel === alfMessageChannelTypes.MAIL
            ? {
                ...mailFormData,
                formattedAddress: Object.values(mailFormData).join(','),
            }
            : contactPartyAddress.length > 0
                ? {
                    formattedAddress: contactPartyAddress.join(','),
                    street: initialContactInformation?.street,
                    postCode: initialContactInformation?.postCode,
                    city: initialContactInformation?.city,
                    country: initialContactInformation?.country,
                }
                : null;

        const recipientType = recipients[selectedRecipient]?.recipientType;
        const serviceCaseAttachments = (selectedAttachments && serviceCaseDocuments)
            ? serviceCaseDocuments.filter(scDocument => selectedAttachments.includes(scDocument.id))
            : [];

        const messageData = {
            recipient: name || recipients[selectedRecipient]?.name || '',
            channel: selectedChannel,
            ...(selectedChannel === alfMessageChannelTypes.EMAIL
                ? {
                    toEmail: emailFormData.toEmail,
                    senderAccountType: emailFormData.sender,
                }
                : {}
            ),
            ...(contactAddress
                ? {toAddress: contactAddress}
                : {}
            ),
            subject,
            content: htmlMessageContent,
            contactId: recipientType === 'MEMBER'
                ? serviceCase?.member?.membershipNo
                : recipientType === 'PRESELECTED_ACCOUNT_PARTY' || recipientType === 'ACCOUNT_PARTY'
                    ? recipients[selectedRecipient].id
                    : '',
            ...(invoice ? {invoiceId: invoice.id} : {}),
            serviceCaseId: serviceCase?.id,
            messageType: alfMessageTypes.OUTGOING,
            attachments: [...fileList.fileAssets.values()]
                .concat([...serviceCaseAttachments])
                .map(attachment => {
                    return {
                        id: attachment.id,
                        fileName: attachment.fileName,
                        isExistingAttachment: !!serviceCaseAttachments.find(scAttachment => (
                            scAttachment.id === attachment.id
                        )),
                    };
                }),
            language: selectedLanguage === languageOptions[languages.GERMAN] ? 'DE' : 'EN',
            zipFileName: `${invoice?.id ? `${invoice.id}-` : ''}${subject}-${translateModal('file_name_text.attachment')}.zip`,
        };

        confirmSendMessage(messageData);

        if (shouldCreateTask && dueDate && dueTime) {
            let category;

            switch (selectedCategory) {
                case alfOutgoingMessageCategoryTypes.REQUEST: {
                    category = etmLeistTaskCategoryTypes.REQUEST;
                    break;
                }
                case alfOutgoingMessageCategoryTypes.REPAYMENT: {
                    category = etmLeistTaskCategoryTypes.AREAS_OF_CONTRIBUTION;
                    break;
                }
                default: {
                    category = etmLeistTaskCategoryTypes.TASK;
                }
            }

            const taskData = {
                category,
                description: translateModal('task_body.description', {
                    channel: translate(`global.communication_channel_option.${selectedChannel.toLowerCase()}`),
                    addressee: (selectedRecipient === 'OTHER' ? name : recipients[selectedRecipient]?.name) || '',
                    subject,
                }),
                dueDate: moment(`${moment(dueDate).format('YYYY-MM-DD')}T${dueTime}`),
                assignedCaseId: serviceCase?.id,
                memberName: serviceCase?.member?.personalDetails.displayName || '',
                assignedTo: activeUser.id,
                assignedToUser: activeUser.id,
                assignedToRole: false,
                taskType: etmTaskTypes.LEA,
                ...(invoice && {invoiceId: invoice.id}),
            };

            createTask({taskData});
        }
    };

    if (!serviceCase && !selectedSearchContact) return null;

    const recipients = {
        ...(serviceCase?.member
            ? {
                [serviceCase?.member.membershipNo]: {
                    recipientType: 'MEMBER',
                    id: serviceCase?.member.membershipNo,
                    name: serviceCase?.member.personalDetails.name,
                    contactDetails: serviceCase?.member.contactDetails,
                    personalDetails: serviceCase?.member.personalDetails,
                },
            }
            : {}
        ),
        ...(selectedSearchContact
            ? {
                [selectedSearchContact.id]: {
                    recipientType: 'PRESELECTED_ACCOUNT_PARTY',
                    ...selectedSearchContact,
                },
            }
            : {}
        ),
    };

    if (serviceCaseInvoices?.length > 0) {
        serviceCaseInvoices.forEach(serviceCaseInvoice => {
            const {accountParty} = serviceCaseInvoice;

            // Note: In MIA cases member is accountParty
            if (accountParty && accountParty.id !== serviceCase?.member?.membershipNo) {
                recipients[accountParty.id] = {recipientType: 'ACCOUNT_PARTY', ...accountParty};
            }
        });
    }

    const handleRecipientSelect = value => {
        if (!value) return;

        if (value === 'OTHER') {
            setInitialContactInformation(null);
            setSelectedRecipient(value);
            return;
        }

        const selectedOption = recipients[value];

        if (selectedOption.recipientType === 'MEMBER') {
            const {contactDetails, personalDetails} = selectedOption;
            setInitialContactInformation({
                ...(initialContactInformation || {}),
                street: personalDetails?.address?.street,
                postCode: personalDetails?.address?.postCode,
                city: personalDetails?.address?.city,
                country: personalDetails?.address?.country,
                toEmail: contactDetails?.email,
            });
        }

        if (selectedOption.recipientType === 'PRESELECTED_ACCOUNT_PARTY') {
            setInitialContactInformation({
                ...(initialContactInformation || {}),
                street: selectedOption.address?.street,
                postCode: selectedOption.address?.postCode,
                city: selectedOption.address?.city,
                country: selectedOption.address?.country,
                toEmail: selectedOption.email || '',
            });
        }

        if (selectedOption.recipientType === 'ACCOUNT_PARTY') {
            const searchQueryParams = new URLSearchParams();
            searchQueryParams.append('client', `${serviceCase?.client}`);
            searchQueryParams.append('id', `${selectedOption.id}`);
            fetchAccountPartyDetails({searchQueryParams});
            setShouldSetContactInformation(true);
        }

        setSelectedRecipient(value);
    };

    const handleChannelSelection = value => {
        if (value === alfMessageChannelTypes.MAIL) {
            setEmailFormData(undefined);
        }

        if (value === alfMessageChannelTypes.EMAIL) {
            setMailFormData(undefined);
        }

        setSelectedChannel(value);
        setEmailError('');

        if (value === alfMessageChannelTypes.ELLA && selectedCategory !== alfMessageChannelTypes.ELLA) {
            setSelectedTemplate('');
            setSubject('');
            setRichTextAreaContent(EditorState.createWithContent(ContentState.createFromText('')));
        }
    };

    const handleOnLanguageChange = value => {
        setSelectedLanguage(value);
        if (selectedCategory) setSubject(translate(translateModal.apply(this, [
            `communication_type_option.${selectedCategory.toLowerCase()}`,
            {},
            value,
        ])));
        if (selectedCategory && selectedTemplate) formatMessageText(selectedCategory, selectedTemplate, value);
    };

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx([
                'ace-c-modal__content--scrollable',
                'global!ace-u-modal-content-size--l',
            ])}
            hasColoredHeader={true}
            title={translateModal('modal_title.send_message')}
            action={(
                <Icon
                    icon={closeIcon}
                    onClick={() => {
                        if (!isSendingInProgress) {
                            declineSendMessage();
                        }
                    }}
                />
            )}
        >
            <ContentBlock className={cx('global!ace-u-full-width')}>
                <ContentItem
                    className={cx([
                        'ace-c-content-item--span-3',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <SelectField
                        name="recipient"
                        label={translateModal('select_field_label.recipient')}
                        onChange={handleRecipientSelect}
                        value={selectedRecipient}
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-32')}
                        isDisabled={isSendingInProgress}
                    >
                        {recipients && Object.keys(recipients).map((recipient, idx) => {
                            return (
                                <Option
                                    name={`recipientOption${idx}`}
                                    value={recipient}
                                    key={recipients[recipient].id}
                                >
                                    {recipients[recipient].name}
                                </Option>
                            );
                        })}
                        <Option
                            name="recipientOptionOther"
                            value="OTHER"
                        >
                            {translateModal('recipient_option_label.other')}
                        </Option>
                    </SelectField>
                    {selectedRecipient === 'OTHER' && (
                        <InputField
                            name="name"
                            label={`${translateModal('input_field_label.name')}*`}
                            className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-32')}
                            value={name || ''}
                            onChange={setName}
                            isDisabled={isSendingInProgress}
                        />
                    )}
                    <SelectField
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-32')}
                        name="channel"
                        label={translateModal('select_field_label.channel')}
                        value={selectedChannel}
                        onChange={value => handleChannelSelection(value)}
                        isDisabled={isSendingInProgress}
                    >
                        {Object.values(alfMessageChannelTypes).filter(channel => (
                            channel !== alfMessageChannelTypes.ELLA
                            || (channel === alfMessageChannelTypes.ELLA
                                && invoice?.channel === alfInvoiceChannelTypes.ELLA)
                        )).map(channel => (
                            <Option
                                key={channel}
                                value={channel}
                                name={`channelOption${channel}`}
                            >
                                {translate(`global.communication_channel_option.${channel.toLowerCase()}`)}
                            </Option>
                        ))}
                    </SelectField>
                    {(selectedChannel === alfMessageChannelTypes.EMAIL
                    || selectedChannel === alfMessageChannelTypes.MAIL) && (
                        <SelectField
                            className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-32')}
                            name="language"
                            label={translateModal('select_field_label.language')}
                            value={selectedLanguage}
                            onChange={value => handleOnLanguageChange(value)}
                            isDisabled={isSendingInProgress}
                        >
                            {Object.keys(languageOptions).map(key => (
                                <Option
                                    key={key}
                                    name={`languageOption${key}`}
                                    value={languageOptions[key]}
                                >
                                    {translateModal(`option_label.${key.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                    )}
                    {selectedChannel === alfMessageChannelTypes.EMAIL && (
                        <Form name="emailForm" onChange={formValues => { handleEmailFormChange(formValues); }}>
                            <SelectField
                                name="sender"
                                label={translateModal('select_field_label.sender')}
                                className={cx('global!ace-u-margin--bottom-32', 'global!ace-u-full-width')}
                                isDisabled={isSendingInProgress}
                            >
                                {senderAccounts && senderAccounts.map(sender => {
                                    return (
                                        <Option
                                            value={sender.type}
                                            name={`emailSender${sender.type}`}
                                            key={sender.type}
                                        >
                                            {sender.email}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <InputField
                                className={cx('global!ace-u-margin--bottom-32', 'global!ace-u-full-width')}
                                name="toEmail"
                                label={translateModal('input_field_label.to_email')}
                                value={initialContactInformation?.toEmail || ''}
                                errors={emailError ? [emailError] : []}
                                isDisabled={isSendingInProgress}
                            />
                        </Form>
                    )}
                    {selectedChannel === alfMessageChannelTypes.MAIL && (
                        <Form name="toAddress" onChange={formValues => { handleMailFormChange(formValues); }}>
                            <InputField
                                className={cx('global!ace-u-margin--bottom-32', 'global!ace-u-full-width')}
                                name="street"
                                label={translateModal('input_field_label.street')}
                                value={initialContactInformation?.street || ''}
                                isDisabled={isSendingInProgress}
                            />
                            <div
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--bottom-32',
                                    'global!ace-u-grid',
                                ])}
                            >
                                <InputField
                                    className={cx(['global!ace-u-grid-column--span-5'])}
                                    name="postCode"
                                    label={translateModal('input_field_label.post_code')}
                                    value={initialContactInformation?.postCode || ''}
                                    isDisabled={isSendingInProgress}
                                />
                                <InputField
                                    className={cx(['global!ace-u-grid-column--span-7'])}
                                    name="city"
                                    label={translateModal('input_field_label.city')}
                                    value={initialContactInformation?.city || ''}
                                    isDisabled={isSendingInProgress}
                                />
                            </div>
                            <InputField
                                className={cx('global!ace-u-margin--bottom-32', 'global!ace-u-full-width')}
                                name="country"
                                label={translateModal('input_field_label.country')}
                                value={initialContactInformation?.country || ''}
                                isDisabled={isSendingInProgress}
                            />
                        </Form>
                    )}
                    <Fragment>
                        <SelectField
                            className={cx('global!ace-u-margin--bottom-32')}
                            name="type"
                            label={translateModal('select_field_label.type')}
                            value={selectedCategory || ''}
                            onChange={value => {
                                setSelectedCategory(value);
                                setRichTextAreaContent(EditorState.createEmpty());
                                setSubject(translate(translateModal.apply(this, [
                                    `communication_type_option.${value.toLowerCase()}`,
                                    {},
                                    selectedLanguage,
                                ])));
                                !categoryToTextTemplatesMap[value].includes(selectedTemplate)
                                    ? setSelectedTemplate('')
                                    : setSelectedTemplate(selectedTemplate);
                            }}
                            isDisabled={isSendingInProgress}
                        >
                            {Object.values(alfOutgoingMessageCategoryTypes).filter(category => (
                                selectedChannel !== alfMessageChannelTypes.ELLA
                                || (selectedChannel === alfMessageChannelTypes.ELLA
                                && category === alfOutgoingMessageCategoryTypes.ELLA)
                            )).map((category, idx) => (
                                <Option
                                    value={category}
                                    name={`category${category}`}
                                    key={`${category}-${idx}`}
                                >
                                    {translateModal(`communication_type_option.${category.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        {selectedCategory && (
                            <SelectField
                                className={cx('global!ace-u-margin--bottom-32')}
                                name="template"
                                label={translateModal('select_field_label.template')}
                                value={selectedTemplate}
                                onChange={value => {
                                    setSelectedTemplate(value);
                                    formatMessageText(selectedCategory, value);
                                }}
                                isDisabled={isSendingInProgress}
                            >
                                {categoryToTextTemplatesMap[selectedCategory].map(textTemplate => (
                                    <Option
                                        name={textTemplate}
                                        key={`textTemplate${textTemplate}`}
                                        value={textTemplate}
                                    >
                                        {translateModal(`text_template_option.${textTemplate.toLowerCase()}`)}
                                    </Option>
                                ))}
                            </SelectField>
                        )}
                    </Fragment>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'ace-c-content-item--span-9',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <InputField
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-32')}
                        name="subject"
                        label={translateModal('input_field_label.subject')}
                        value={subject || ''}
                        onChange={setSubject}
                        isDisabled={isSendingInProgress}
                    />
                    <RichTextAreaField
                        name="content"
                        label={translateModal('text_area_field_label.message')}
                        maxLength={config.MESSAGE_MAX_LENGTH}
                        setEditorState={value => {
                            setRichTextAreaContent(value);
                        }}
                        editorState={richTextAreaContent}
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-16')}
                        contentClassName={cx('ace-c-text-area--medium')}
                        locale={activeLocale}
                        isResizable={false}
                        placeholder={translateModal('text_area_field_placeholder.enter_message')}
                        isDisabled={isSendingInProgress}
                        shouldFocus={false}
                    />
                    {!contactsScreenMatch && !serviceCaseScreenMatch && (
                        <div className={cx('global!ace-u-full-width', 'global!ace-u-grid')}>
                            <MultiSelectField
                                name="attachments"
                                label={translateModal('select_field_label.attachments')}
                                className={cx('global!ace-u-grid-column--span-8')}
                                value={selectedAttachments || ''}
                                onChange={setSelectedAttachments}
                            >
                                {(serviceCaseDocuments || []).map(attachment => (
                                    <MultiSelectOption
                                        className="global!ace-u-full-width"
                                        key={`${attachment.id}-${attachment.fileName}`}
                                        name={`option-${attachment.id}`}
                                        value={attachment.id}
                                        isSelected={true}
                                    >
                                        { `${attachment.attachmentType ? `${translate(`global.document_type.${attachment.attachmentType.toLowerCase()}`)} | ` : ''}${attachment.fileName}`}
                                    </MultiSelectOption>
                                ))}
                            </MultiSelectField>
                        </div>
                    )}
                    <ContentBlock>
                        <ContentItem className={cx('ace-c-content-item--span-8')}>
                            {selectedChannel !== alfMessageChannelTypes.ELLA && (
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-height--full',
                                    ])}
                                >
                                    {[...fileList.fileAssets.values()].length > 0
                                    && (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid-column--span-5',
                                                'ace-c-communication-modal__file-list',
                                            ])}
                                        >
                                            {[...fileList.fileAssets.values()].map((fileAsset, index) => (
                                                <div
                                                    key={fileAsset.id}
                                                    className={cx([
                                                        'global!ace-u-padding--16',
                                                        'global!ace-u-grid',
                                                        'global!ace-u-box-skin--pending',
                                                    ], {
                                                        'global!ace-u-margin--top-16': index !== 0,
                                                    })}
                                                >
                                                    <Icon
                                                        icon={fileIcon}
                                                    />
                                                    <p
                                                        className={cx([
                                                            'global!ace-u-grid-column--span-7',
                                                            'ace-c-communication-modal__file-name',
                                                        ])}
                                                    >
                                                        {fileAsset.fileName}
                                                    </p>
                                                    <Icon
                                                        onClick={() => deleteFile(
                                                            fileAsset,
                                                            config.SHOULD_SEND_DOCUMENT_DELETE_REQUEST,
                                                        )}
                                                        icon={deleteIcon}
                                                        className={cx([
                                                            'ace-c-icon--xs',
                                                            'ace-c-icon--color-medium-emphasis',
                                                        ])}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div
                                        className={cx([
                                            'global!ace-u-width--full',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                        ], {
                                            'global!ace-u-grid-column--span-7': [...fileList.fileAssets.values()].length > 0,
                                            'global!ace-u-grid-column--span-12': [...fileList.fileAssets.values()].length === 0,
                                        })}
                                    >
                                        <FileAssetsDropZone
                                            service="alfFileAssetsService"
                                            fileList="service-case-incoming-messages"
                                            className={cx([
                                                'global!ace-u-padding--40',
                                                'global!ace-u-width--full',
                                                'global!ace-u-height--full',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-flex--justify-center',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                            isDisabled={isSendingInProgress}
                                        >
                                            <HighlightCircle
                                                className={cx([
                                                    'global!ace-u-height--64',
                                                    'global!ace-u-width--64',
                                                    'ace-c-highlight-circle--primary-highlight',
                                                ])}
                                            >
                                                <Icon
                                                    icon={uploadIcon}
                                                    className={cx([
                                                        'ace-c-icon--l',
                                                    ])}
                                                />
                                            </HighlightCircle>
                                            <div
                                                className={cx([
                                                    'global!ace-u-margin--top-16',
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                    'global!ace-u-flex--align-center',
                                                ])}
                                            >
                                                <p className={cx('global!ace-u-typography--variant-body-bold')}>
                                                    {translateModal('file_drop_zone_title.document_upload')}
                                                </p>
                                                <FileAssetsInput
                                                    service="alfFileAssetsService"
                                                    fileList="service-case-incoming-messages"
                                                    className={cx([
                                                        'global!ace-u-margin--16-0',
                                                        'ace-c-file-input--no-chrome',
                                                    ])}
                                                    isDisabled={isSendingInProgress}
                                                >
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                            'global!ace-u-flex--justify-center',
                                                            'global!ace-u-cursor--pointer',
                                                        ])}
                                                    >
                                                        <Icon
                                                            icon={plusIcon}
                                                            className={cx([
                                                                'global!ace-u-margin--right-8',
                                                                'ace-c-icon--color-highlight-pressed',
                                                            ])}
                                                        />
                                                        <p
                                                            className={cx([
                                                                'global!ace-u-typography--variant-highlight',
                                                                'global!ace-u-typography--color-higlighted-active',
                                                                'global!ace-u-typography--align-center',
                                                            ])}
                                                        >
                                                            {translateModal('file_drop_zone_label.search_documents')}
                                                        </p>
                                                    </div>
                                                </FileAssetsInput>
                                            </div>
                                        </FileAssetsDropZone>
                                        {fileList.errors.length > 0 && (
                                            <div className={cx('global!ace-u-margin--8-0')}>
                                                {fileList.errors.map((errorInfo, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={cx([
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--align-center',
                                                                'global!ace-u-margin--4-0',
                                                                'global!ace-u-typography--variant-caption',
                                                                'global!ace-u-typography--color-warning',
                                                            ])}
                                                        >
                                                            <Icon
                                                                icon={infoAlertIcon}
                                                                className={cx([
                                                                    'global!ace-u-margin--right-4',
                                                                    'ace-c-icon--s',
                                                                    'ace-c-icon--color-warning',
                                                                ])}
                                                            />
                                                            {translateModal('upload_error.message')}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </ContentItem>
                        <ContentItem
                            className={cx([
                                'ace-c-content-item--span-4',
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                            ])}
                        >
                            {!selectedSearchContact?.id && (
                                <Fragment>
                                    <Checkbox
                                        name="createTask"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        onChange={setShouldCreateTask}
                                        isSelected={!!shouldCreateTask}
                                        isDisabled={isSendingInProgress}
                                    >
                                        {translateModal('checkbox_label.create_task')}
                                    </Checkbox>
                                    <DateField
                                        name="dueDate"
                                        icon={calendarIcon}
                                        label={`${translateModal('date_field_label.due_date')}${shouldCreateTask ? '*' : ''}`}
                                        value={dueDate}
                                        onChange={setDueDate}
                                        className={cx('global!ace-u-margin--bottom-8')}
                                        isDisabled={isSendingInProgress}
                                    />
                                    <TimeField
                                        name="dueTime"
                                        label={`${translateModal('time_field_label.due_time')}${shouldCreateTask ? '*' : ''}`}
                                        value={dueTime}
                                        onChange={setDueTime}
                                        className={cx('global!ace-u-margin--bottom-16')}
                                        isDisabled={isSendingInProgress}
                                        isInvalid={dueTime ? !isDueTimeValid : false}
                                        handleValidation={setIsDueTimeValid}
                                    />
                                </Fragment>
                            )}
                            <ButtonPrimary
                                isDisabled={isSendingDisabled || isSendingInProgress}
                                onClick={handleOnMessageSend}
                            >
                                <Icon
                                    icon={emailIcon}
                                    className={cx([
                                        'global!ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateModal('button_label.send_message')}
                            </ButtonPrimary>
                        </ContentItem>
                    </ContentBlock>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

SendMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    serviceCase: PropTypes.object,
    serviceCaseInvoices: PropTypes.arrayOf(PropTypes.object),
    declineSendMessage: PropTypes.func.isRequired,
    confirmSendMessage: PropTypes.func.isRequired,
    fetchAccountPartyDetails: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    senderAccounts: PropTypes.array,
    accountPartyContactDetails: PropTypes.object,
    invoice: PropTypes.object,
    serviceCaseInvoice: PropTypes.object,
    activeUser: PropTypes.object,
    selectedSearchContact: PropTypes.object,
    persistenceState: PropTypes.string.isRequired,
    serviceCaseDocuments: PropTypes.array,
    location: PropTypes.object,
};

SendMessageModal.defaultProps = {
    hasBackdrop: true,
    serviceCaseInvoices: [],
    senderAccounts: [],
    accountPartyContactDetails: null,
    invoice: null,
    serviceCase: null,
    serviceCaseInvoice: null,
    activeUser: null,
    selectedSearchContact: null,
    serviceCaseDocuments: [],
    location: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const serviceCaseInvoicesSelector = invoiceSelectors.createServiceCaseInvoicesSelector();
    const createServiceCaseInvoiceSelector = invoiceSelectors.createServiceCaseInvoiceSelector();
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getSelectedContact = contactSelectors.createContactSelector();

    return {
        serviceCase: getServiceCase(state, props),
        serviceCaseInvoices: serviceCaseInvoicesSelector(state, props),
        senderAccounts: state.communication.senderAccounts,
        accountPartyContactDetails: state.communication.accountPartyContactDetails,
        invoice: getInvoice(state, props),
        serviceCaseInvoice: createServiceCaseInvoiceSelector(state, props),
        activeUser: userProfileSelectors.getUser(state),
        selectedSearchContact: getSelectedContact(state, props),
        persistenceState: state.communication.persistenceState,
        serviceCaseDocuments: state.serviceCases.serviceCaseDocuments,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        declineSendMessage: payload => {
            dispatch({
                type: communicationActionTypes.DECLINE_SEND_MESSAGE,
                payload,
            });
        },
        confirmSendMessage: payload => {
            dispatch({
                type: communicationActionTypes.CONFIRM_SEND_MESSAGE,
                payload,
            });
        },
        fetchAccountPartyDetails: payload => {
            dispatch({
                type: communicationActionTypes.FETCH_ACCOUNT_PARTY_CONTACT_DETAILS,
                payload,
            });
        },
        createTask: payload => {
            dispatch({
                type: taskActionTypes.CREATE_TASK,
                payload,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageModal);
